<template>
<b-card>
    <b-form @submit="onSubmit">
        <h3 class="pt-1">
                General Detail
            </h3>
            <b-row class="pt-1">
            <b-col
            md="6"
            >
                <b-form-group
                    label="Grouped Org"
                >
                    <b-form-input
                        v-model="groupOrg"
                        placeholder="Grouped Org"
                        required="required"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                    label="Phone Number"
                >
                    <b-form-input
                        v-model="phoneNumber"
                        placeholder="Phone Number"
                        required="required"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col
            md="6"
            >
                <b-form-group
                    label="Email"
                >
                    <b-form-input
                        v-model="email"
                        placeholder="Email"
                        required="required"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                label="Customer"
                >
                    <custom-autosuggestmul
                        table="clients"
                        label="client_name"
                        multiple="true"
                        :value="client"
                        @changed="clientSelected"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                label="Status"
                >
                    <b-form-checkbox
                        v-model="isActive"
                        switch
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <h3 class="pt-1">
                Physical Address
            </h3>
            <b-row class="pt-1">
            <b-col md="6">
                <b-form-group
                label="Address 1 [mandatory]"
                >
                    <b-form-input
                        v-model="phyAdd1"
                        placeholder="Address 1 [mandatory]"
                        required="required"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                    label="Address 2 [optional]"
                >
                    <b-form-input
                        v-model="phyAdd2"
                        placeholder="Address 2 [optional]"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                    label="Address 3 [optional]"
                >
                    <b-form-input
                        v-model="phyAdd3"
                        placeholder="Address 3 [optional]"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <h3 class="pt-1">
                Postal Address
            </h3>
            <b-row class="pt-1">
            <b-col md="6">
                <b-form-group
                label="Address 1 [mandatory]"
                >
                    <b-form-input
                        v-model="postAdd1"
                        placeholder="Address 1 [mandatory]"
                        required="required"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                    label="Address 2 [optional]"
                >
                    <b-form-input
                        v-model="postAdd2"
                        placeholder="Address 2 [optional]"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                    label="Address 3 [optional]"
                >
                    <b-form-input
                        v-model="postAdd3"
                        placeholder="Address 3 [optional]"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-button
        type="submit"
        variant="primary"
        >
            Submit
        </b-button>
        <GoBack />
    </b-form>
</b-card>
</template>

<script>
import {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BFormCheckbox,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import GoBack from '../common/goBack.vue';
import CustomAutosuggestmul from '../common/components/CustomAutosuggestmul.vue';
export default {
    components: {
        BCard,
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        // BFormSelect,
        GoBack,
        BButton,
        BFormCheckbox,
        CustomAutosuggestmul,
    },
    data() {
        return {
            id: 0,
            field: [],
            email: '',
            phoneNumber: '',
            clientId: [],
            clientName: [],
            client: [],
            url: '',
            isActive: true,
            groupOrg: '',
            phyAdd1: '',
            phyAdd2: '',
            phyAdd3: '',
            postAdd1: '',
            postAdd2: '',
            postAdd3: '',
        };
    },
    created() {
        if (this.$route.params.id) {
        this.id = this.$route.params.id;
        this.url = `/companies/view/${this.id}`;
        } else {
            this.url = `/companies/createView`;
        }
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$helper.showLoading();
            this.$axios.get(this.url)
                .then(res => {
                    this.field = res.data.fields;
                    // console.log(res.data);
                    if (this.id) {
                        this.groupOrg = res.data.results.result.name;
                        this.phoneNumber = res.data.results.result.phone_number;
                        this.email = res.data.results.result.email;
                        this.phyAdd1 = res.data.fields.physical_address.value;
                        this.phyAdd2 = res.data.fields.physical_address2.value;
                        this.phyAdd3 = res.data.fields.physical_address3.value;
                        this.postAdd1 = res.data.fields.postal_address.value;
                        this.postAdd2 = res.data.fields.postal_address2.value;
                        this.postAdd3 = res.data.fields.postal_address3.value;
                        this.isActive = (res.data.results.result.is_active === 1);
                        this.client = res.data.fields.client_id.value;
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
                this.$helper.hideLoading();
        },
        async onSubmit(event) {
            event.preventDefault();
            let request = {};
            const formData = new FormData();
            formData.append('client_id', this.clientId);
            formData.append('email', this.email);
            formData.append('phone_number', this.phoneNumber);
            formData.append('name', this.groupOrg);
            formData.append('is_active', this.isActive);
            formData.append('physical_address', this.phyAdd1);
            formData.append('physical_address2', this.phyAdd2);
            formData.append('physical_address3', this.phyAdd3);
            formData.append('postal_address', this.postAdd1);
            formData.append('postal_address2', this.postAdd2);
            formData.append('postal_address3', this.postAdd3);
            request = formData;
            // console.log(request);
            let url = '';
            if (this.id) {
                url = `/companies/update/${this.id}`;
            } else {
                url = `/companies/save`;
            }
            this.$axios.post(url, request)
                .then(res => {
                    // console.log(res);
                    if (this.id === 0) {
                        this.$router.push(`/account/companies`).catch(() => {});
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Record Updated',
                                icon: 'CheckIcon',
                                variant: 'primary',
                            },
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
        clientSelected(val) {
            if (val.length > 0) {
                this.clientId = val.map(item => item.id);
                this.client = val;
            } else {
                this.clientId = 0;
                this.client = val;
            }
        },
    },
}
</script>
