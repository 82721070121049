<template>
<div>
    <v-select
        :label="label"
        :options="options"
        multiple
        :disabled="disabled"
        :value="value"
        @search="userInput"
        @option:selected="optionChosen"
        @option:deselected="optionRemove"
    />
</div>
</template>
<script>
import vSelect from 'vue-select';
export default {
    name: 'CustomAutosuggest',
    components: { vSelect },
    props: {
        table: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: 'name',
        },
        value: {
            default: 0,
        },
        limit: {
            type: Number,
            default: 10,
        },
        url: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            options: [],
            selected: null,
            disabled: true,
            search: '',
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            const endpoint = this.url ?? `autopopulate`;
            this.$axios.get(endpoint, {
                params: {
                    table: this.table,
                    column: this.label,
                    limit: this.limit,
                    search: this.search,
                },
            }).then((response) => {
                this.options = response.data.results;
                this.disabled = false;
            }).catch((err) => console.error(err));
        },
        userInput(val) {
            this.search = val;
            this.fetchData();
        },
        optionChosen(val) {
            console.log('add option', this.value)
            this.$emit('changed', val);
        },
        optionRemove(val) {
            this.value.splice(this.value.indexOf(val), 1)
            this.$emit('changed', this.value);
        },
    },
}
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
